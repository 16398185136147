import Head from 'next/head';
import Image from 'next/image';
import useRouter from 'next/router';
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { ThemeOptions } from '@material-ui/core/styles';

import { Rating } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTranslation from 'next-translate/useTranslation';

import MPartnerLogos from 'components/molecules/MPartnerLogos';
import ALink from 'components/atoms/ALink';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import OPricingSection from 'components/organisms/Landing/OPricingSection';
import OExcellentSection from 'components/organisms/Landing/OExcellentSection';
import OBoostYourChangeSection from 'components/organisms/Landing/OBoostYourChangeSection';
import WithAuthSync from 'middleware/authMiddleware';
import { PrimaryTabs, TabPanel } from 'components/molecules/MTabs';
import { AppState } from 'redux-saga/interfaces';
import { verifyEmail } from 'redux-saga/actions';
import { useNonInitialEffect } from 'helpers';

const Home = () => {
  const isSmAndDown = useMediaQuery((theme: ThemeOptions) => theme.breakpoints.down('sm'));
  const { t } = useTranslation('home');
  const { t: commonT } = useTranslation('common');
  const [tab, setTab] = useState(0);
  const { error, verifyEmailResponse, userInfo } = useSelector((state: AppState) => state);
  const { enqueueSnackbar } = useSnackbar();
  const tabs = [
    { key: 'All', text: commonT('tabs.all') },
    { key: 'Free', text: commonT('tabs.free') },
    { key: 'Premium', text: commonT('tabs.premium') },
  ];
  const isLoggedIn = !!userInfo.token;
  const templates = {
    All: [
      {
        image: '/assets/cv_demo_1.png',
        component: 'OCV1',
      },
      {
        image: '/assets/cv_demo_2.png',
        component: 'OCV2',
      },
      {
        image: '/assets/cv_demo_3.png',
        component: 'OCV3',
      },
    ],
    Free: [
      {
        image: '/assets/cv_demo_1.png',
        component: 'OCV1',
      },
      {
        image: '/assets/cv_demo_4.png',
        component: 'OCV2',
      },
      {
        image: '/assets/cv_demo_3.png',
        component: 'OCV3',
      },
    ],
    Premium: [
      {
        image: '/assets/cv_demo_2.png',
        component: 'OCV1',
      },
      {
        image: '/assets/cv_premium_demo_2.png',
        component: 'OCV2',
      },
      {
        image: '/assets/cv_premium_demo_1.png',
        component: 'OCV3',
      },
    ],
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const key = useRouter.router.query.key as string;
    if (key) dispatch(verifyEmail({ key }));
  }, []);

  useNonInitialEffect(() => {
    if (verifyEmailResponse) {
      enqueueSnackbar(t('verifiedAccount'), { variant: 'success' });
      useRouter.push('/');
    }
  }, [verifyEmailResponse]);

  useNonInitialEffect(() => {
    if (error && error.detail === 'Not found.') {
      enqueueSnackbar(error.detail, { variant: 'error' });
      useRouter.push('/');
    }
  }, [error]);

  const handleChangeTabs = (_, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Box component="main" overflow="auto" className="u-hide-x-overflow">
      <Head>
        <title>Urban CV</title>
        <meta name="description" content="Create the Best CV in under 5 minutes" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap"
          rel="stylesheet"
        />
      </Head>
      <Box className="home-header">
        <Header />
        <Box mt={{ xs: 15, md: 20 }}>
          <Container>
            <Grid container direction="row-reverse" alignItems="center">
              <Grid item xs={12} md={6} className="home-hero-images-wrap">
                <div className="home-hero-cv">
                  <Image
                    src="/assets/cv_3.png"
                    alt="CV"
                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKCAIAAAAYbLhkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAx0lEQVQImSWMsQ7BUBRAb2LwC1b9B5tP8AO22owSPyAdpDHwCC1RbZfXDlqGNhJum9ikBklNb3tjF7oIalHBGU9yDkjd3nYXpGnKGPM8DxGhPyBzTbMty3Ec0zC+StcNQgilFH8kSQKEEFEUJUkaDYez6TSOY9AWOqX0f/F9nzH2DWVZRsRXlkVRlOdvcF2Xc549H8fTuVqr26sNUGpdrtc8fwf7Q1GomPYaxhNVVRRE3IYhlIRGqwOIyDl/3G/L9QYK5Wa78wGVKHgov+Hm4AAAAABJRU5ErkJggg=="
                    placeholder="blur"
                    width={isSmAndDown ? 200 : 384}
                    height={isSmAndDown ? 314 : 603}
                  />
                </div>
                <div className="home-hero-vector">
                  <Image
                    src="/assets/vector_2.png"
                    alt="vector"
                    blurDataURL="/_next/image?url=/assets/vector_2.png&w=16&q=1"
                    placeholder="blur"
                    width={510}
                    height={393}
                  />
                </div>
                <div className="home-hero-cv-card">
                  <div className="home-hero-cv-card__rating">
                    <Rating name="size-small-2" defaultValue={5} size="small" disabled />
                    <p>1 {commonT('common.dayAgo')}</p>
                  </div>
                  <h4 className="home-hero-cv-card__name">Matt</h4>
                  <p className="home-hero-cv-card__job">{commonT('common.jobSeeker')}</p>
                  <p className="home-hero-cv-card__desc">{t('fastAndQuick')}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <h1>{t('createTheBestCV')}</h1>
                <Box mt={{ xs: 5, md: 12 }} mb={{ xs: 8, md: 15 }} className="home-hero-desc">
                  {t('chooseFromAWideRangeOfProfessional')}
                </Box>
                <ALink href="/register">
                  <Button variant="contained" color="secondary" className="c-primary-button c-primary-button--orange">
                    {t('createCvNow')}
                  </Button>
                </ALink>
                <Box mt={{ xs: 20, md: 50 }}>
                  <Box className="o-body-semibold1" color="#484545">
                    {t('ourPartners')}
                  </Box>
                  <MPartnerLogos />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <OExcellentSection />
      <Box pt={{ xs: 20, md: 60 }} pb={{ xs: 20, md: 40 }} className="home-weaponize">
        <Container>
          <Grid container alignItems="center">
            <Grid item xs={12} md={6} className="home-hero-images-wrap">
              <div className="home-hero-cv">
                <Image
                  src="/assets/cv_3.png"
                  alt="CV"
                  blurDataURL="/_next/image?url=/assets/cv_3.png&w=16&q=1"
                  placeholder="blur"
                  width={isSmAndDown ? 200 : 384}
                  height={isSmAndDown ? 314 : 603}
                />
              </div>

              <div className="home-hero-vector">
                <Image
                  src="/assets/vector_2.png"
                  alt="vector"
                  blurDataURL="/_next/image?url=/assets/vector_2.png&w=16&q=1"
                  placeholder="blur"
                  width={510}
                  height={393}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mt={{ xs: 12, md: 0 }}>
                <h2>{t('weaponizeYourCv')}</h2>
                <div className="home-weaponize-card">
                  <h4>{t('startBuildingCv')}</h4>
                  <p className="home-weaponize-card__desc">{t('createCvNow')}</p>
                </div>
                <div className="home-weaponize-card home-weaponize-card--paper">
                  <h4>{t('education')}</h4>
                  <p className="home-weaponize-card__desc">{t('educationalDetails')}</p>
                </div>
                <div className="home-weaponize-card">
                  <h4>{t('experience')}</h4>
                  <p className="home-weaponize-card__desc">{t('workExperienceMatters')}</p>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box pt={{ xs: 12, md: 22 }} pb={20} bgcolor="#fbfbfb">
        <Container>
          <h2 className="u-text-center">{t('professionalCv')}</h2>
          <p className="o-body-regular1 u-text-center u-mt-20">{t('chooseYourTemplate')}</p>
          <PrimaryTabs tabs={tabs} active={tab} onChange={handleChangeTabs} />
          {tabs.map((_, tabIndex) => {
            return (
              <TabPanel key={tabIndex} value={tab} index={tabIndex}>
                <Box mt={{ xs: 10, md: 30 }}>
                  <Grid container>
                    {templates[tabs[tab].key] &&
                      templates[tabs[tab].key].map((template, templateIndex) => {
                        return (
                          <Grid item xs={12} md={4} key={templateIndex}>
                            <Box mb={12} ml={10}>
                              <Image
                                src={template.image}
                                alt={template.component}
                                blurDataURL={`/_next/image?url=${template.image}&w=16&q=1`}
                                placeholder="blur"
                                layout="responsive"
                                width={isSmAndDown ? 300 : 383}
                                height={isSmAndDown ? 480 : 613}
                              />
                            </Box>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Box>
              </TabPanel>
            );
          })}
        </Container>
      </Box>
      <OPricingSection />
      <Box pt={{ xs: 20, md: 40 }} pb={{ xs: 20, md: 40 }} className="home-why-urban">
        <Container>
          <Grid container direction="row-reverse" alignItems="center">
            <Grid item xs={12} md={6} className="home-hero-images-wrap">
              <div className="home-hero-cv">
                <Image
                  src="/assets/cv_3.png"
                  alt="CV"
                  blurDataURL="/_next/image?url=/assets/cv_3.png&w=16&q=1"
                  placeholder="blur"
                  width={isSmAndDown ? 200 : 384}
                  height={isSmAndDown ? 314 : 603}
                />
              </div>
              <div className="home-hero-vector">
                <Image
                  src="/assets/vector_2.png"
                  alt="vector"
                  blurDataURL="/_next/image?url=/assets/vector_2.png&w=16&q=1"
                  placeholder="blur"
                  width={510}
                  height={393}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mt={{ xs: 20, md: 0 }}>
                <h2>{t('whyUrbanCv')}?</h2>
                <Box mt={20} display="flex">
                  <Box width={40} mr={8}>
                    <Image
                      src="/assets/widest_range.svg"
                      alt="Widest Range"
                      blurDataURL="/_next/image?url=/assets/widest_range.svg&w=16&q=1"
                      placeholder="blur"
                      width={40}
                      height={40}
                    />
                  </Box>
                  <Box width="calc(100% - 56px)">
                    <h4>{t('widestRange')}</h4>
                    <Box mt={7}>{t('overTheYears')}</Box>
                  </Box>
                </Box>
                <Box mt={20} display="flex">
                  <Box width={40} mr={8}>
                    <Image
                      src="/assets/deepest_layer.svg"
                      alt="Deepest layer"
                      blurDataURL="/_next/image?url=/assets/deepest_layer.svg&w=16&q=1"
                      placeholder="blur"
                      width={40}
                      height={40}
                    />
                  </Box>
                  <Box width="calc(100% - 56px)">
                    <h4>{t('deepestLayers')}</h4>
                    <Box mt={7}>{t('buildYourCvDeeply')}</Box>
                  </Box>
                </Box>
                <Box mt={20} display="flex">
                  <Box width={40} mr={8}>
                    <Image
                      src="/assets/approval.svg"
                      alt="Approval"
                      blurDataURL="/_next/image?url=/assets/approval.svg&w=16&q=1"
                      placeholder="blur"
                      width={40}
                      height={40}
                    />
                  </Box>
                  <Box width="calc(100% - 56px)">
                    <h4>{t('smartCvBuilding')}</h4>
                    <Box mt={7}>{t('diamondsCreatedUnderPressure')}</Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <OBoostYourChangeSection isLoggedIn={isLoggedIn} />
      <Footer />
    </Box>
  );
};

export default WithAuthSync(Home);
