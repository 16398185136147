import UniversalCookie from 'universal-cookie';
import moment from 'moment';
var cookie = new UniversalCookie();
export function setCookieAccessToken(_ref) {
  var token = _ref.token,
      expireAt = _ref.expireAt;
  cookie.set('token', token, {
    path: '/',
    expires: moment(moment(expireAt)).toDate()
  });
}
export function setCookieCvSelected(data) {
  cookie.set('cvSelected', data, {
    path: '/'
  });
}
export function getAccessToken() {
  return cookie.get('token');
}
export function revokeData() {
  var options = {
    path: '/'
  };
  cookie.remove('token', options);
  cookie.remove('cvSelected', options);
}