import { FC } from 'react';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

interface TabsProps {
  active: number;
  tabs: { key: string; text: string }[];
  className?: string;
  onChange?: (event: React.ChangeEvent, value: number) => void;
}

export const PrimaryTabs: FC<TabsProps> = ({
  tabs = [],
  active = 0,
  className = 'u-mt-30 c-tabs',
  onChange = () => {
    //
  },
}) => (
  <Tabs
    value={active}
    onChange={onChange}
    className={className}
    TabIndicatorProps={{
      style: {
        display: 'none',
      },
    }}
    variant="scrollable"
    scrollButtons="on"
  >
    {tabs.map(({ key, text }) => (
      <Tab label={text} key={key} />
    ))}
  </Tabs>
);

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

export const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}>
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);
