import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Router from 'next/router';
import Cookies from 'universal-cookie';

import { resetStore } from 'redux-saga/actions';
import { getAccessToken } from 'utils/cookie';

const middlewareHandler = (token, cvSelected, ctx) => {
  const { res, pathname } = ctx;
  const isLoggedIn = !!token;
  const isPathNoAuth = ['/login', '/register', '/forgot-password', '/reset-password'].includes(pathname);
  const isPublicPath = ['/', '/about', '/sample-cv', '/sample-cv/[name]'].includes(pathname);
  const isPaymentUrl = ['/checkout', '/payment-success', '/payment-failed'].includes(pathname);
  const isAccessWithFreeCv = isPaymentUrl && cvSelected && isPaymentUrl && !cvSelected.is_premium;
  const isPathRequireCvSelected = isPaymentUrl || pathname === '/onboarding/steps';
  if (res && !isPublicPath && typeof window === 'undefined') {
    if (!isPathNoAuth && !isLoggedIn) {
      res.writeHead(302, { Location: '/login' });
      res.end();
    } else if (isPathRequireCvSelected && isLoggedIn && (!cvSelected || isAccessWithFreeCv)) {
      res.writeHead(302, { Location: '/my-cvs' });
      res.end();
    } else if (isPathNoAuth && isLoggedIn) {
      res.writeHead(302, { Location: '/onboarding' });
      res.end();
    }
  } else if (!isPublicPath) {
    if (!isPathNoAuth && !isLoggedIn) Router.push('/login');
    if (isPathNoAuth && isLoggedIn) Router.push('/onboarding');
  }
};

const WithAuthSync = (WrappedComponent) => {
  const FuncComponent = ({ children, ...props }) => {
    const dispatch = useDispatch();
    useEffect(() => {
      if (!props.cookieToken) dispatch(resetStore());
    }, []);
    return <WrappedComponent {...props}>{children}</WrappedComponent>;
  };

  FuncComponent.getInitialProps = async (ctx) => {
    const { req, res, store } = ctx;
    const data = store.getState();
    const { token } = data.userInfo;
    let { cvSelected } = data;
    let cookieToken = null;
    if (res) {
      const cookies = new Cookies(req.headers.cookie);
      cvSelected = cookies.get('cvSelected');
      cookieToken = cookies.get('token');
    } else {
      cookieToken = token && getAccessToken() ? getAccessToken() : null;
    }
    if (!cookieToken) store.dispatch(resetStore());
    middlewareHandler(cookieToken, cvSelected, ctx);
    const props = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));
    return { ...props, cookieToken };
  };

  return FuncComponent;
};

export default WithAuthSync;
